// @flow

import { ListFilters } from '@performant-software/semantic-components';
import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import AssociatedRecordCountCell from '../components/AssociatedRecordCountCell';
import BuildingUsageService from '../services/BuildingUsages';
import BuildingUsageFilters from '../filters/BuildingUsageFilters';

const BuildingUsages: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='building-usages'
    collectionName='building_usages'
    searchable
    filters={{
      component: ListFilters,
      props: {
        filters: BuildingUsageFilters
      }
    }}
    columns={[{
      name: 'name',
      label: props.t('BuildingUsages.columns.name'),
      sortable: true
    }, {
      name: 'building_type_name',
      label: props.t('BuildingUsages.columns.buildingType'),
      sortable: false
    }, {
      name: 'building_usages_sites_count',
      label: props.t('BuildingUsages.columns.associatedSites'),
      sortable: true,
      render: (item) => (
        <AssociatedRecordCountCell
          item={item}
          name='building_usages_sites_count'
          recordType='site'
          recordTypeLabel={props.t('AssociatedRecordsButton.labels.sites')}
          associationName='building_usages_sites'
          filterType='building_usage'
          filterTypeLabel={props.t('AssociatedRecordsButton.labels.buildingUsages')}
        />
      )
    }, {
      name: 'building_usages_publications_count',
      label: props.t('BuildingUsages.columns.associatedPublications'),
      sortable: true,
      render: (item) => (
        <AssociatedRecordCountCell
          item={item}
          name='building_usages_publications_count'
          recordType='publication'
          recordTypeLabel={props.t('AssociatedRecordsButton.labels.publications')}
          associationName='building_usages_publications'
          filterType='building_usage'
          filterTypeLabel={props.t('AssociatedRecordsButton.labels.buildingUsages')}
        />
      )
    }]}
    onDelete={(buildingUsage) => BuildingUsageService.delete(buildingUsage)}
    onLoad={(params) => BuildingUsageService.search(params)}
    route='building_usages'
  />
));

export default BuildingUsages;
