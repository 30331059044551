// @flow

import type { BuildingType as BuildingTypeType } from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import type { Filter } from '../types/Filter';
import BuildingType from '../transforms/BuildingType';
import BuildingTypes from '../services/BuildingTypes';

const BuildingUsageFilters: Array<Filter> = [{
  attributeName: 'name',
  key: 'name',
  label: i18n.t('BuildingUsages.filters.name'),
  type: FilterTypes.string
}, {
  associationColumn: 'building_type_id',
  associationName: 'building_type',
  attributeName: 'id',
  collectionName: 'building_types',
  key: 'building_type_id',
  label: i18n.t('BuildingUsages.filters.buildingType'),
  objectName: 'building_type',
  onSearch: (search: string) => BuildingTypes.fetchAll({ search, sort_by: 'name' }),
  renderOption: (buildingType: BuildingTypeType) => BuildingType.toDropdown(buildingType),
  renderSearchQuery: (buildingType: BuildingTypeType) => buildingType.name,
  type: FilterTypes.relationship
}];

export default BuildingUsageFilters;
