// @flow

import type { BuildingUsage as BuildingUsageType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import SimpleEditPage from './SimpleEditPage';
import BuildingUsageForm from '../components/BuildingUsageForm';
import BuildingUsages from '../services/BuildingUsages';

type Props = EditContainerProps & Translateable & {
  item: BuildingUsageType
};

const Tabs = {
  details: 'details'
};

const BuildingUsage: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
  >
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <BuildingUsageForm
        {...props}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: BuildingUsage,
  onInitialize: (id: number): Promise<any> => (
    BuildingUsages
      .fetchOne(id)
      .then(({ data }) => data.building_usage)
  ),
  onSave: (buildingUsage: BuildingUsageType): Promise<any> => (
    BuildingUsages
      .save(buildingUsage)
      .then(({ data }) => data.building_usage)
  )
};
