import type { BuildingUsage as BuildingUsageType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';

type Props = Translateable & {
  ...EditContainerProps,
  item: BuildingUsageType
};

const AddBuildingTypeModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='building-type-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.t('BuildingTypeModal.title.new')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        label={props.t('BuildingTypeModal.labels.buildingTypeName')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));
export default AddBuildingTypeModal;
