// @flow

import React from 'react';
import ViewAssociatedRecordButton from './ViewAssociatedRecordsButton';

type AssociatedRecordCountCellProps = {
  associationColumn?: string;
  attributeName?: string;
  item: any,
  name: string,
  recordType: string,
  recordTypeLabel: string,
  associationName: string,
  filterType: string,
  filterTypeLabel: string,
  filterKey?: string
};

const AssociatedRecordCountCell = (props: AssociatedRecordCountCellProps) => (
  <div
    style={
      {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        width: '50%'
      }
    }
  >
    <span
      style={
        {
          paddingRight: '18px'
        }
      }
    >
      {props.item[props.name]}
    </span>
    {props.item[props.name] > 0 && (
      <ViewAssociatedRecordButton
        associationColumn={props.associationColumn}
        attributeName={props.attributeName}
        recordType={props.recordType}
        recordTypeLabel={props.recordTypeLabel}
        filterID={props.item.id}
        filterName={props.item.name}
        associationName={props.associationName}
        filterType={props.filterType}
        filterTypeLabel={props.filterTypeLabel}
        filterKey={props.filterKey}
      />
    )}
  </div>
);

AssociatedRecordCountCell.defaultProps = {
  associationColumn: undefined,
  attributeName: undefined,
  filterKey: undefined,
};

export default AssociatedRecordCountCell;
