// @flow

import React, { useRef, type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import Authority from './Authority';
import Authorities from './Authorities';
import BuildingType from './BuildingType';
import BuildingTypes from './BuildingTypes';
import BuildingUsage from './BuildingUsage';
import BuildingUsages from './BuildingUsages';
import Collection from './Collection';
import CollectionType from './CollectionType';
import CollectionTypes from './CollectionTypes';
import Collections from './Collections';
import Countries from './Countries';
import Country from './Country';
import CustomPage from './CustomPage';
import CustomPages from './CustomPages';
import DisplayDocument from './DisplayDocument';
import Donation from './Donation';
import Donations from './Donations';
import Donor from './Donor';
import Donors from './Donors';
import EditContainerWrapper from './EditContainerWrapper';
import EventType from './EventType';
import EventTypes from './EventTypes';
import HomePage from './HomePage';
import HomePages from './HomePages';
import Keyword from './Keyword';
import Keywords from './Keywords';
import MediaContent from './MediaContent';
import MediaContents from './MediaContents';
import NotFound404 from './NotFound404';
import Publication from './Publication';
import Publications from './Publications';
import ScrollViewContext from '../contexts/ScrollViewContext';
import Site from './Site';
import Sites from './Sites';
import StylePeriod from './StylePeriod';
import StylePeriods from './StylePeriods';
import User from './User';
import Users from './Users';
import './Admin.css';

const Admin: AbstractComponent<any> = withTranslation()(withRouter(() => {
  const containerRef = useRef<?HTMLDivElement>(null);

  const scrollToTop = () => containerRef.current && containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div
      className='admin'
    >
      <AdminSidebar />
      <ScrollViewContext.Provider
        value={{
          containerRef,
          scrollToTop
        }}
      >
        <div
          className='content-container'
          ref={containerRef}
        >
          <Switch>
            <Route
              path='/admin/404'
              component={NotFound404}
            />
            <Route
              path='/admin/authorities/new'
            >
              <EditContainerWrapper
                {...Authority}
              />
            </Route>
            <Route
              path='/admin/authorities/:id'
            >
              <EditContainerWrapper
                {...Authority}
              />
            </Route>
            <Route
              path='/admin/authorities'
              component={Authorities}
            />
            <Route
              path='/admin/collection_types/new'
            >
              <EditContainerWrapper
                {...CollectionType}
              />
            </Route>
            <Route
              path='/admin/collection_types/:id'
            >
              <EditContainerWrapper
                {...CollectionType}
              />
            </Route>
            <Route
              path='/admin/collection_types'
              component={CollectionTypes}
            />
            <Route
              path='/admin/collections/new'
            >
              <EditContainerWrapper
                {...Collection}
              />
            </Route>
            <Route
              path='/admin/collections/:id'
            >
              <EditContainerWrapper
                {...Collection}
              />
            </Route>
            <Route
              path='/admin/collections'
              component={Collections}
            />
            <Route
              path='/admin/countries/new'
            >
              <EditContainerWrapper
                {...Country}
              />
            </Route>
            <Route
              path='/admin/countries/:id'
            >
              <EditContainerWrapper
                {...Country}
              />
            </Route>
            <Route
              path='/admin/countries'
              component={Countries}
            />
            <Route
              path='/admin/custom_pages/new'
            >
              <EditContainerWrapper
                {...CustomPage}
              />
            </Route>
            <Route
              path='/admin/custom_pages/:id'
            >
              <EditContainerWrapper
                {...CustomPage}
              />
            </Route>
            <Route
              path='/admin/custom_pages'
              component={CustomPages}
            />
            <Route
              path='/admin/donations/new'
            >
              <EditContainerWrapper
                {...Donation}
              />
            </Route>
            <Route
              path='/admin/donations/:id'
            >
              <EditContainerWrapper
                {...Donation}
              />
            </Route>
            <Route
              path='/admin/donations'
              component={Donations}
            />
            <Route
              path='/admin/donors/new'
            >
              <EditContainerWrapper
                {...Donor}
              />
            </Route>
            <Route
              path='/admin/donors/:id'
            >
              <EditContainerWrapper
                {...Donor}
              />
            </Route>
            <Route
              path='/admin/donors'
              component={Donors}
            />
            <Route
              path='/admin/event_types/new'
            >
              <EditContainerWrapper
                {...EventType}
              />
            </Route>
            <Route
              path='/admin/event_types/:id'
            >
              <EditContainerWrapper
                {...EventType}
              />
            </Route>
            <Route
              path='/admin/event_types'
              component={EventTypes}
            />
            <Route
              path='/admin/home_pages/new'
            >
              <EditContainerWrapper
                {...HomePage}
              />
            </Route>
            <Route
              path='/admin/home_pages/:id'
            >
              <EditContainerWrapper
                {...HomePage}
              />
            </Route>
            <Route
              path='/admin/home_pages'
              component={HomePages}
            />
            <Route
              path='/admin/media_contents/new'
            >
              <EditContainerWrapper
                {...MediaContent}
              />
            </Route>
            <Route
              path='/admin/media_contents/:id'
            >
              <EditContainerWrapper
                {...MediaContent}
              />
            </Route>
            <Route
              path='/admin/media_contents'
              component={MediaContents}
            />
            <Route
              path='/admin/publications/new'
            >
              <EditContainerWrapper
                {...Publication}
              />
            </Route>
            <Route
              path='/admin/publications/:id'
            >
              <EditContainerWrapper
                {...Publication}
              />
            </Route>
            <Route
              path='/admin/publications'
              component={Publications}
            />
            <Route
              path='/admin/sites/new'
            >
              <EditContainerWrapper
                {...Site}
              />
            </Route>
            <Route
              path='/admin/sites/:id'
            >
              <EditContainerWrapper
                {...Site}
              />
            </Route>
            <Route
              path='/admin/sites'
              component={Sites}
            />
            <Route
              path='/admin/style_periods/new'
            >
              <EditContainerWrapper
                {...StylePeriod}
              />
            </Route>
            <Route
              path='/admin/style_periods/:id'
            >
              <EditContainerWrapper
                {...StylePeriod}
              />
            </Route>
            <Route
              path='/admin/style_periods'
              component={StylePeriods}
            />
            <Route
              path='/admin/keywords/new'
            >
              <EditContainerWrapper
                {...Keyword}
              />
            </Route>
            <Route
              path='/admin/keywords/:id'
            >
              <EditContainerWrapper
                {...Keyword}
              />
            </Route>
            <Route
              path='/admin/keywords'
              component={Keywords}
            />
            <Route
              path='/admin/building_usages/new'
            >
              <EditContainerWrapper
                {...BuildingUsage}
              />
            </Route>
            <Route
              path='/admin/building_usages/:id'
            >
              <EditContainerWrapper
                {...BuildingUsage}
              />
            </Route>
            <Route
              path='/admin/building_usages'
              component={BuildingUsages}
            />
            <Route
              path='/admin/building_types/new'
            >
              <EditContainerWrapper
                {...BuildingType}
              />
            </Route>
            <Route
              path='/admin/building_types/:id'
            >
              <EditContainerWrapper
                {...BuildingType}
              />
            </Route>
            <Route
              path='/admin/building_types'
              component={BuildingTypes}
            />
            <Route
              path='/admin/users/new'
            >
              <EditContainerWrapper
                {...User}
              />
            </Route>
            <Route
              path='/admin/users/:id'
            >
              <EditContainerWrapper
                {...User}
              />
            </Route>
            <Route
              path='/admin/users'
              component={Users}
            />
            <Route
              path='/admin/display'
              component={DisplayDocument}
            />
            <Redirect
              to='/admin/sites'
            />
          </Switch>
        </div>
      </ScrollViewContext.Provider>
    </div>
  );
}));

export default Admin;
