// @flow

import NestedAttributes from './NestedAttributes';

import type { HomePage } from '@archnet/shared';

/**
 * Class for handling home page items records.
 */
class HomePageItems extends NestedAttributes {
  PARAM_NAME: string = 'home_page_items';

  /**
   * Returns the home page items payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'item_id',
      'item_type',
      'label',
      'url',
      '_destroy'
    ];
  }

  /**
   * Returns the collection of home page items for the passed home page to be sent on POST/PUT requests.
   *
   * @param record
   * @param collection
   *
   * @returns {{}}
   */
  toPayload(record: HomePage, collection: string = this.PARAM_NAME): any {
    return super.toPayload(record, collection);
  }
}

export default (new HomePageItems(): HomePageItems);
