// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './ViewAssociatedRecordsButton.css';

type ViewAssociatedRecordsButtonProps = {
  associationColumn?: string;
  attributeName?: string;
  recordType: string;
  recordTypeLabel: string;
  associationName: string;
  filterType: string;
  filterTypeLabel: string;
  filterName: string;
  filterID: number;
  filterKey?: string;
};

const ViewAssociatedRecordButton = (props: ViewAssociatedRecordsButtonProps) => {
  const history = useHistory();

  // create the object to send to session storage
  const filterValue = {
    filters: {
      filters: [
        {
          associationColumn: props.associationColumn || `${props.recordType}_id`,
          associationName: props.associationName,
          attributeName: props.attributeName || `${props.associationName}.${props.filterType}_id`,
          collectionName: `${props.filterType}s`,
          key: props.filterKey || `${props.associationName}.${props.filterType}_id`,
          label: props.filterTypeLabel,
          objectName: props.filterType,
          type: 'relationship',
          operator: 'equal',
          searchQuery: props.filterName,
          value: props.filterID
        }
      ]
    }
  };
  // click handler function
  const handleClick = () => {
    window.sessionStorage.setItem(`DataList.${props.recordType}s`, JSON.stringify(filterValue));
    history.push(`/admin/${props.recordType}s`);
  };
  return (
    <button
      type='button'
      className='associated-records-button'
      onClick={handleClick}
    >
      {props.recordTypeLabel}
      <Icon
        style={{ paddingLeft: '6px' }}
        name='arrow alternate circle right outline'
      />
    </button>
  );
};

ViewAssociatedRecordButton.defaultProps = {
  associationColumn: undefined,
  attributeName: undefined,
  filterKey: undefined,
};

export default ViewAssociatedRecordButton;
