// @flow

import type { BuildingUsage as BuildingUsageType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type Node } from 'react';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import { Form } from 'semantic-ui-react';
import BuildingTypes from '../services/BuildingTypes';
import BuildingType from '../transforms/BuildingType';
import AddBuildingTypeModal from './AddBuildingTypeModal';

type Props = EditContainerProps & Translateable & {
  item: BuildingUsageType
};

const BuildingUsageForm = (props: Props): Node => (
  <>
    <Form.Input
      error={props.isError('name')}
      label={props.t('BuildingUsage.labels.name')}
      onChange={props.onTextInputChange.bind(this, 'name')}
      required={props.isRequired('name')}
      value={props.item.name || ''}
    />
    <Form.Input
      label={props.t('BuildingUsage.labels.buildingType')}
      required={props.isRequired('building_type_id')}
    >
      <AssociatedDropdown
        collectionName='building_types'
        onSearch={(search) => BuildingTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })}
        onSelection={props.onAssociationInputChange.bind(this, 'building_type_id', 'building_type')}
        renderOption={(bt) => BuildingType.toDropdown(bt)}
        searchQuery={props.item.building_type && props.item.building_type.name}
        value={props.item.building_type_id || ''}
        modal={{
          component: AddBuildingTypeModal,
          onSave: (buildingType) => BuildingTypes.save(buildingType).then(({ data }) => data.building_type)
        }}
      />
    </Form.Input>
  </>
);

export default BuildingUsageForm;
