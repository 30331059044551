// @flow

import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import AssociatedRecordCountCell from '../components/AssociatedRecordCountCell';
import BuildingTypeService from '../services/BuildingTypes';

const BuildingTypes: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='building-types'
    collectionName='building_types'
    searchable
    columns={[{
      name: 'name',
      label: props.t('BuildingTypes.columns.name'),
      sortable: true
    }, {
      name: 'building_usages_count',
      label: props.t('BuildingTypes.columns.associatedBuildingUsages'),
      sortable: true,
      render: (item) => (
        <AssociatedRecordCountCell
          item={item}
          name='building_usages_count'
          associationColumn='building_type_id'
          associationName='building_type'
          recordType='building_usage'
          recordTypeLabel={props.t('AssociatedRecordsButton.labels.buildingUsages')}
          attributeName='id'
          filterType='building_type'
          filterTypeLabel={props.t('AssociatedRecordsButton.labels.buildingTypes')}
          filterKey='building_type_id'
        />
      )
    }, {
      name: 'sites_count',
      label: props.t('BuildingTypes.columns.associatedSites'),
      sortable: true,
      render: (item) => (
        <AssociatedRecordCountCell
          item={item}
          name='sites_count'
          recordType='site'
          recordTypeLabel={props.t('AssociatedRecordsButton.labels.sites')}
          associationName='building_types_sites'
          attributeName='building_usages_sites.building_usage.building_type_id'
          filterType='building_type'
          filterTypeLabel={props.t('AssociatedRecordsButton.labels.buildingTypes')}
          filterKey='building_type_sites.building_type_id'
        />
      )
    }, {
      name: 'publications_count',
      label: props.t('BuildingTypes.columns.associatedPublications'),
      sortable: true,
      render: (item) => (
        <AssociatedRecordCountCell
          item={item}
          name='publications_count'
          recordType='publication'
          recordTypeLabel={props.t('AssociatedRecordsButton.labels.publications')}
          associationName='building_types_publications'
          attributeName='building_usages_publications.building_usage.building_type_id'
          filterType='building_type'
          filterTypeLabel={props.t('AssociatedRecordsButton.labels.buildingTypes')}
          filterKey='building_type_publications.building_type_id'
        />
      )
    }]}
    onDelete={(buildingType) => BuildingTypeService.delete(buildingType)}
    onLoad={(params) => BuildingTypeService.fetchAll(params)}
    route='building_types'
  />
));

export default BuildingTypes;
