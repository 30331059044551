// @flow

import React, { type Node } from 'react';
import { Label } from 'semantic-ui-react';
import cx from 'classnames';
import styles from './TypeLabel.module.css';

type Props = {
  type: string
};

const Colors = {
  Authority: 'red',
  Collection: 'orange',
  Image: 'yellow',
  MediaContent: 'yellow',
  Publication: 'green',
  Site: 'blue',
  Video: 'teal'
};

const Icons = {
  Authority: 'user',
  Collection: 'grid layout',
  Image: 'image',
  MediaContent: 'image',
  Publication: 'file',
  Site: 'building',
  Video: 'video'
};

const TypeLabel = (props: Props): Node => {
  return (
    <Label
      color={Colors[props.type]}
      content={props.type}
      icon={Icons[props.type]}
      className={cx(
        styles.ui,
        styles.label,
        styles.darkText,
        {
          [styles.red]: Colors[props.type] === 'red' 
        }
      )}
    />
  );
}

export default TypeLabel;
